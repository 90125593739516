import React from 'react'
import {Helmet} from "react-helmet";
import Markdown from 'react-markdown'
import { Link } from 'react-router-dom'
import Toggler from '../components/Toggler'
import Loader from '../components/Loader'


const NoteTemplate = (props) => {
    const [loading, setLoading] = React.useState(true);
    const [note, setNote] = React.useState({});
    React.useEffect(()=>{
        fetch(`https://sharadcodes.github.io/notes/${props.match.params.slug}.json`).then(r=>r.json()).then(d=>{
            setNote(d)
            setLoading(false)         
        })
    },[props.match.params.slug])

    return (
        !loading ?
        <>
            <Helmet>
                <title>{note.data.title}</title>
            </Helmet>        
            <header>
                <Toggler />
                <h1>
                    {note.data.title}
                </h1>
            </header>
            <div className="meta">
                <div className="note-date">
                    {note.data.date}
                </div>
                <div className="note-tags">
                    {note.data.tags.map((t, i) => (
                        <Link key={i} to={`/tags/${t}`}>{t}</Link>)
                    )}
                </div>
            </div>
            <div id="content" className="content">
                <div className="note-content">
                    <Markdown source={note.content} escapeHtml={false} />
                </div>
            </div>
        </>:
        <Loader/>
    )
}

export default NoteTemplate;