import React from 'react'
import NoteCard from "../NoteCard"
import Toggler from '../Toggler'
import {Helmet} from "react-helmet";

export default (props) => { 
    const pageTitle = props.match.params.tag
    const [loading, setLoading] = React.useState(true);
    const [notes, setNotes] = React.useState([]);
    React.useEffect(()=>{
        fetch("https://sharadcodes.github.io/notes/meta.json").then(r=>r.json()).then(d=>{
            if(pageTitle) { 
                setNotes(d[pageTitle].notes)
            } else {
                setNotes(d['ALL'])
            }
            setLoading(false)
        })
    },[pageTitle])

    return (
        !loading &&
        <>
            <Helmet>
                <title>{pageTitle ? pageTitle : "All Notes"}</title>
            </Helmet> 
            <header>
                <Toggler />
                <h1>{pageTitle ? pageTitle : "All Notes"}</h1>
            </header>
            <div id="content" className="content">
                {notes.map((note, i) => <NoteCard key={i} title={note.title} date={note.date} slug={note.slug} tags={note.tags} />)}
            </div>
        </>
    )
}

