import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './app.css'
import SideNav from './components/SideNav'
import NotesByTagTemplate from './containers/NotesByTagTemplate'
import NoteTemplate from "./containers/NoteTemplate"
import NoteLister from "./components/NoteLister"

const App =() => {

const [data, setData] = React.useState({});
const siteTitle = "The Notes of sharadcodes"
const userImageUrl = "https://avatars2.githubusercontent.com/u/36638057?s=460&u=0cbe4fd6b5a3655d91b3eb86db834d0829a0c086&v=4"

React.useEffect(()=>{
    fetch("https://sharadcodes.github.io/notes/meta.json").then(r=>r.json()).then(d=>{
        setData(d)
    })
},[])

return (
    <Router>
        <main>
            <SideNav siteTitle={siteTitle} tags={data} userImageUrl={userImageUrl}/>
            <div className="content-wrapper">
                    <Route path="/" exact component={NotesByTagTemplate}/>
                    <Route path="/tags/:tag" exact component={NoteLister}/>
                    <Route path="/note/:slug" exact component={NoteTemplate}/>
            </div>
        </main>
    </Router>
)
}

export default App
